import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Carousel from '../../Components/SmallCarousel';
import * as mock from '../../MockData';
import withPageEntry from '../../withPageEntry';

let POOL_MOCK = [
  '../../images/care-1.jpg',
  '../../images/care-2.jpg',
  '../../images/care-3.jpg',
  '../../images/care-4.jpg',
];

function Services(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');

  const [bannerDimension, setBannerDimension] = React.useState(null);
  const [selectedRoomTypeIdx, setSelectedRoomTypeIdx] = React.useState(0);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  let babyRoomImgWidth =
    dimension.rwd === 'desktop'
      ? (dimension.innerWidth - 200) / 4
      : (dimension.innerWidth - 40) / 2;

  return (
    <Wrapper rwd={dimension.rwd} dimension={dimension} id="rev-ServicePage">
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="service"
          src="../../images/service-banner-1.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">FAMILY FRIENDLY</div>
        <div className="title">友善家庭</div>
        <div className="carousel-wrapper">
          <div style={{flex: 1}}>
            <Carousel
              width={
                dimension.rwd === 'desktop'
                  ? dimension?.innerWidth / 2
                  : dimension?.innerWidth - 40
              }
              height={
                dimension.rwd === 'desktop'
                  ? (dimension.innerWidth / 2) * 0.66
                  : dimension.innerWidth * 0.66
              }
              items={mock.SERVICE_CAROUSAL_MOCK}
              // style={{position: 'absolute', top: 0, left: 0}}
            />
          </div>
          <div style={{flexBasis: 40}} />
          <div style={{flex: 1}}>
            {/* <div className="title">青田悅的友善大寶政策</div> */}
            <p className="content">
              {`希望來到青田悦的每個人都像回到家一樣的溫暖
我們秉持著照顧一整個家庭的心情
打造了友善大寶的溫暖入住環境，多種大寶備品借用免煩惱
讓大寶們能用愛與快樂陪伴著爸比媽咪及新生命的到來
提醒爸爸媽媽，小寶出生也別忘了照顧大寶的心情 
讓大寶了解即將有一個寶寶會在未來加入我們的生活及家庭 
讓大寶一同參與懷孕的過程
孩子們的情緒很直白，對父母的佔有也很直白，也會表達失落
試著用孩子們能懂的話語 
一句『我愛你』
搭配特別有的專屬空間及青田悅準備的各種不同讀物!
每天給大寶一小段獨佔媽咪的時間。
化解大寶的心結，也減緩哥哥姊姊的分離焦慮。
`}
            </p>
          </div>
        </div>
      </section>
      <section className="section-dark">
        <div className="subtitle">Nursing Care</div>
        <div className="title">醫護照護</div>
        <p
          className="content"
          style={{
            marginTop: 40,
          }}>{`堅強的團隊成員不只產後護理經歷，更具備新生兒病房、開刀房、產房等臨床經驗，由專業合格的護理人員全天候輪班，包含傷口照護、哺乳教學、產後衛教…等，並每日詳實記錄寶寶照護情形，隨時與爸爸媽媽討論育兒大小事，提供貼心溫暖的專業照護。

與桃園區教學醫院--敏盛醫院合作，醫療後送制度完善，並由敏盛小兒科主任率領團隊醫師定期巡診，提供整體性照護服務。

青田悅秉直著「尊重、同理與支持」精神，陪伴著爸爸媽媽，用旅行的心情，一起期待、準備、從容應對旅程中的點點滴滴。`}</p>
        <div className="pj-row wrap" style={{marginTop: 40}}>
          {POOL_MOCK.map((img, idx) => (
            <img
              alt={`pool-${idx}`}
              src={img}
              style={{
                width: babyRoomImgWidth,
                height: babyRoomImgWidth,
                objectFit: 'cover',
              }}
            />
          ))}
        </div>
      </section>

      <section className="section">
        <div className="subtitle">CLASS & FORUM</div>
        <div className="title">親子教室</div>
        <p
          className="content"
          style={{marginTop: 40}}>{`誰說月子中心只開課給媽媽?
誰說月子中心只照護媽媽及小寶?
青田悅擁有大片落地玻璃的親子教室
溫潤的木頭色配上暮光的景色
期待每個來到這裡的爸爸媽媽
除了學習
也可以停下腳步
好好休息一下
`}</p>
      </section>

      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          src="../../images/service-banner-2.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">SERVICE</div>
        <div className="title">暖心服務</div>
        <div className="pj-row" style={{padding: '20px 0px'}}>
          {mock.SERVICE_MOCK.map((room, idx) => (
            <div
              onClick={() => setSelectedRoomTypeIdx(idx)}
              className={`tab ${
                selectedRoomTypeIdx === idx ? 'selected' : ''
              }`}>
              <p className="content">{room.name}</p>
            </div>
          ))}
        </div>
        <div className="carousel-wrapper">
          <div style={{flex: 1}}>
            <Carousel
              width={
                dimension.rwd === 'desktop'
                  ? dimension?.innerWidth / 2
                  : dimension?.innerWidth - 40
              }
              height={
                dimension.rwd === 'desktop'
                  ? (dimension.innerWidth / 2) * 0.66
                  : dimension.innerWidth * 0.66
              }
              items={mock.SERVICE_MOCK[selectedRoomTypeIdx].imagesArray}
              // style={{position: 'absolute', top: 0, left: 0}}
            />
          </div>

          <div style={{flexBasis: 40}} />
          <div style={{flex: 1}}>
            <div className="title">
              {mock.SERVICE_MOCK[selectedRoomTypeIdx].name}
            </div>
            {mock.SERVICE_MOCK[selectedRoomTypeIdx].info.map((data, idx) => (
              <div>
                {/* <div className="subtitle" style={{marginTop: 20}}>{data.title}</div>	 */}
                <p className="content">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="section-dark">
        <div className="subtitle">FAMILY INTERACTION</div>
        <div className="title">親子互動</div>
        <p className="content" style={{marginTop: 40}}>{`藉由青田悅搭起的橋樑
與寶寶建立起親密的接觸
良好的親子互動有助於寶寶的安定
讓一切從愛開始
`}</p>
      </section>

      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          src="../../images/service-banner-3.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);

  & > .banner {
    width: 100%;
    height: 600px;
    /* position: relative; */
    /* background-color: var(--primaryColor); */
  }

`;

export default withPageEntry(Services);
